import "./Login.css";
import { Anchor, Button, Input } from "antd";
import { useState } from "react";
import httpHelper from "../../services/http-helper";
import { useHistory } from "react-router-dom";
const { Link } = Anchor;

const isValidEmail = (str: string): boolean => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
    return true;
  } else {
    return false;
  }
};

const isPasswordValid = (pw: string): boolean => {
  if (pw.length < 6) {
    return false;
  }
  //   if (pw.search(/[a-z]/i) < 0) {
  //     return false;
  //   }
  if (pw.search(/[0-9]/) < 0) {
    return false;
  }
  return true;
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const history = useHistory();

  const loginFn = async () => {
    if (!isValidEmail(email)) {
      alert("Email is not valid");
    } else if (!isPasswordValid(pw)) {
      alert(
        "Password not valid. Must be 6 characters in length and must have at least 1 number and at least 1 letter"
      );
    } else {
      try {
        const res = await httpHelper.executeGetWithUsernamePass("authorization.php?login=true", email, pw);
        history.push("/home");
      } catch (e: any) {
        alert(e?.response?.data ?? 'Cannot login, fatal error');
      }
    }
    // setuj token iz kontexta
  };
  return (
    <div className="login-wrapper">
      <h2>Charts API</h2>
      <div className="login-dialog">
        <Input
          placeholder="Email"
          size="large"
          value={email}
          onChange={(ev) => {
            setEmail(ev.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              loginFn();
            }
          }}
        />
        <br />
        <Input.Password
          placeholder="Password"
          size="large"
          value={pw}
          onChange={(ev) => {
            setPw(ev.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              loginFn();
            }
          }}
        />
        <br />
        <Button type="primary" onClick={loginFn}>
          Login
        </Button>
        <br />
        <div className="other-options">
          <Anchor>
            <Link href="/register" title="No account? Register" />
          </Anchor>
          <Anchor>
            <Link href="/forgot" title="Forgot password?" />
          </Anchor>
        </div>
      </div>
    </div>
  );
};

export default Login;
