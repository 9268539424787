import './Header.css';
import { PageHeader, Button, DatePicker } from 'antd';
import React, { useState } from 'react';
import LoginContext from '../../context/login/login-context';
import { Select } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

const SelectComponent: React.FC<{ handleChange: ((value: string, option: any) => void) }> = (props) => {
    return (
        <Select defaultValue="total" style={{ width: 120 }} onChange={props.handleChange}>
            <Option value="total" key="total">Total</Option>
            <Option value="custom">Custom</Option>
            <Option value="today">Today</Option>
            <Option value="tomorrow">Tomorrow</Option>
            <Option value="yesterday">Yesterday</Option>
            <Option value="this_week">This week</Option>
            <Option value="last_week">Last week</Option>
            <Option value="next_week">Next week</Option>
            <Option value="last_7_days">Last 7 days</Option>
            <Option value="last_30_days">Last 30 days</Option>
            <Option value="next_7_days">Next 7 days</Option>
            <Option value="next_15_days">Next 15 days</Option>
            <Option value="next_30_days">Next 30 days</Option>
            <Option value="this_month">This month</Option>
            <Option value="next_month">Next month</Option>
            <Option value="last_month">Last month</Option> 
        </Select>
    );
}

const logout = () => {
    localStorage.clear();
    window.location.href = '/';
}

const dateChange = (values: any[], formatString: string[], changeCallback: (r: string[]) => void) => {
    console.log('START DATE CHANGE');
    console.log(values);
    if (!!values && values.length > 0) {
        console.log(values[0].toDate());
        changeCallback(values.map(v => v.format('YYYY-MM-DD')));
    } else {
        changeCallback([]);
    }
}

const HeaderDesktop = () => {
    const [rangeType, setRangeType] = useState<string>('total');

    const value = React.useContext(LoginContext);
    const onRangeTypeChange: (val: string, option: any) => void = (val, option) => {
        setRangeType(val);

        const newVals: any[] = [];

        //dateChange pozovi
        switch (val) {
            case 'total':
                break;
            case 'custom':
                return;
            case 'today':
                newVals.push(dayjs().startOf('day'));
                newVals.push(dayjs().endOf('day'));
                break;
            case 'tomorrow':
                newVals.push(dayjs().add(1, 'day').startOf('day'));
                newVals.push(dayjs().add(1, 'day').endOf('day'));
                break;
            case 'yesterday':
                newVals.push(dayjs().add(-1, 'day').startOf('day'));
                newVals.push(dayjs().add(-1, 'day').endOf('day'));
                break;
            case 'this_week':
                newVals.push(dayjs().startOf('week'));
                newVals.push(dayjs().endOf('week'));
                break;
            case 'last_week':
                newVals.push(dayjs().add(-7, 'day').startOf('week'));
                newVals.push(dayjs().add(-7, 'day').endOf('week'));
                break;
            case 'next_week':
                newVals.push(dayjs().add(7, 'day').startOf('week'));
                newVals.push(dayjs().add(7, 'day').endOf('week'));
                break;
            case 'last_7_days':
                newVals.push(dayjs().add(-7, 'day').startOf('day'));
                newVals.push(dayjs().endOf('day'));
                break;
            case 'last_30_days':
                newVals.push(dayjs().add(-30, 'day').startOf('day'));
                newVals.push(dayjs().endOf('day'));
                break;
            case 'next_7_days':
                newVals.push(dayjs().startOf('day'));
                newVals.push(dayjs().add(7, 'day').endOf('day'));
                break;
            case 'next_15_days':
                newVals.push(dayjs().startOf('day'));
                newVals.push(dayjs().add(15, 'day').endOf('day'));
                break;
            case 'next_30_days':
                newVals.push(dayjs().startOf('day'));
                newVals.push(dayjs().add(30, 'day').endOf('day'));
                break;
            case 'this_month':
                newVals.push(dayjs().startOf('month'));
                newVals.push(dayjs().endOf('month'));
                break;
            case 'next_month':
                newVals.push(dayjs().add(1, 'month').startOf('month'));
                newVals.push(dayjs().add(1, 'month').endOf('month'));
                break;
            case 'last_month':
                newVals.push(dayjs().add(-1, 'month').startOf('month'));
                newVals.push(dayjs().add(-1, 'month').endOf('month'));
                break;

        }

        dateChange(newVals, [], value!.setRange);
    }

    return (
        <div className="header-wrapper">
            <PageHeader
                title="Charts API"
                extra={[
                    <SelectComponent key="select-comp" handleChange={onRangeTypeChange}></SelectComponent>,
                    <RangePicker onChange={(values, formatString) => dateChange(values as any[], formatString, value!.setRange)}
                        disabled={rangeType !== 'custom'} key="range-picker" />,
                    <Button key="logout-btn" type="primary" onClick={logout}>
                        Logout
                    </Button>
                ]}
                avatar={{ src: 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4' }}
            >
            </PageHeader>
        </div>
    );
};

export default HeaderDesktop;