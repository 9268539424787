const taskStatusMap: any[] = [];

taskStatusMap.push(null);
taskStatusMap.push('Opened'); // 1
taskStatusMap.push('Awaiting feedback'); // 2
taskStatusMap.push('In design'); // 3
taskStatusMap.push('Content creation'); // 4
taskStatusMap.push('Completed'); // 5
taskStatusMap.push('Approved'); // 6

export default taskStatusMap;