import "./TableCard.css";
import { Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import httpHelper from "../../services/http-helper";
import taskStatusMap from "../../models/task-status";
import dayjs from "dayjs";
import TableHeader, {
  IRangeData,
  ITableColumns,
  RangeDataOptions,
} from "../../common/table-header/TableHeader";
import getColumnSearchProps, {
  filterColumnProps,
} from "../../common/table-search/table-search";
import ImageWithHover from "../../common/image-with-hover/ImageWithHover";
import React from "react";

const STORAGE_FILTER_KEY = "STORAGE_FILTER_KEY";

/**
 * Converts time in MS to readable duration
 * @param t Total miliseconds
 * @returns Readable duration
 */
export const dhm = (t: number, excludeDays: boolean = false) => {
  var cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60000);
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }

  let totalStr = ``;
  if (d > 0 && !excludeDays) {
    totalStr += `${d} days, `;
  } else if (d > 0) {
    h = h + d * 24;
  }

  if (h > 0) {
    totalStr += `${h} hours, `;
  }

  totalStr += `${m} minutes `;

  return totalStr;
};

export const secondsToHms = (d: number) => {
  if (isNaN(d)) {
    return "try again";
  }
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay; //+ sDisplay;
};

interface ITableData {
  clientId: string;
  datefinished: Date;
  deadline?: Date;
  duedate?: Date;
  startTimes: string;
  endTimes: string;
  firstname: string;
  id: number;
  lastName: string;
  taskname: string;
  name: string;
  profile_image: string;
  staffId: number;
  start_date: Date;
  startdate: Date;
  status: number;
  taskId: number;
}

type cardFilter = {
  loading: boolean;
  data: ITableData[];
  pagination: {
    current: number;
    pageSize: number;
    total: number;
    totalTime: number;
  };
  groupBy: string;
  range: any;
  sorting: any;
  searchFilter: string[];
  hideInactive: boolean;
};

let sortingDirection: string = "";
let sortingKey: string = "";

export type SettingsType = {
  name: string;
  shownColumnNames: string[];
  groupBy: string;
  range: any;
  sorting: any;
  searchFilter: string[];
};

const getData = async (
  current = 1,
  pageSize = 10,
  from: Date | null,
  to: Date | null,
  groupBy: string | null,
  sorting: { order: string; field: string },
  searchFilter: string[],
  componentState: cardFilter,
  range: any
) => {
  const res = await httpHelper.post<
    unknown,
    { data: ITableData[]; total: number; totalTime: number }
  >("chart-helper.php", {
    method: "getTable",
    page: current - 1,
    perPage: pageSize,
    startDate: from?.toLocaleString(),
    endDate: to?.toLocaleString(),
    groupBy: groupBy,
    sorting,
    searchFilter,
    range: range,
    hideInactive: componentState.hideInactive,
  });

  componentState.loading = false;
  componentState.data = [...res.data];
  componentState.pagination = {
    current: current,
    pageSize: pageSize,
    total: res.total,
    totalTime: res.totalTime,
  };
  componentState.range = {
    from: from ?? undefined,
    to: to ?? undefined,
  };
  componentState.groupBy = groupBy ?? "";
  componentState.sorting = sorting ?? { order: "", field: "" };
  componentState.searchFilter = searchFilter;

  return { ...componentState };
};

const totalTimeFromTableData = (record: ITableData) => {
  const startTimes = record.startTimes.split(",");
  const endTimes = record.endTimes?.split(",") ?? "";

  let totalMs = 0;
  for (let i = 0; i < startTimes.length; i++) {
    const sTime = startTimes[i];
    const eTime = endTimes[i];

    const sDate = dayjs.unix(+sTime);
    const eDate = dayjs.unix(+eTime);

    totalMs += eDate.diff(sDate);
  }

  return totalMs;
};

const UseTableCard = (props: { rangeFilter?: string[]; allStaff: any[] }) => {
  const taskStatusOptions: { label: string; value: number }[] = [];

  taskStatusMap
    .filter((f) => !!f)
    .forEach((pr, index) => {
      if (!!taskStatusMap[index]) {
        taskStatusOptions.push({ label: taskStatusMap[index], value: index });
      }
    });

  const columns: ColumnsType<ITableData> = [
    {
      title: "Task Name",
      dataIndex: "taskname",
      sorter: true,
      render: (name: string, record) => {
        return (
          <a
            className="href-fill"
            href={"https://portal.mateam.net/admin/tasks/view/" + record.taskId}
            target="_blank"
            rel="noreferrer"
          >
            {name}
          </a>
        );
      },
      ...getColumnSearchProps("taskname", (newVal: string) => {
        performSearchByField("tsk.name", newVal, "like");
      }),
    },
    {
      title: "Tasks Status",
      dataIndex: "status",
      sorter: true,
      render: (status: string) => (
        <div className="task-status">{taskStatusMap[+status]}</div>
      ),
      width: 150,
      ...filterColumnProps(
        taskStatusMap,
        (newVals: number[]) => {
          if (!newVals || newVals.length === 0) {
            performSearchByField("1", 1, "numberEq");
            return;
          }
          performSearchByField("proj.status", newVals, "in");
        },
        taskStatusOptions
      ),
    },
    {
      title: "Task started",
      dataIndex: "startdate",
      sorter: true,
      render: (startTime: number) =>
        `${dayjs(startTime).format("YYYY-MM-DD HH:mm")}`,
    },
    {
      title: "Task finished",
      dataIndex: "datefinished",
      sorter: true,
      render: (i: string, record: ITableData, index: number) => {
        if (!!record.datefinished) {
          return `${dayjs(record.datefinished!).format("YYYY-MM-DD HH:mm")}`;
        } else {
          return `-`;
        }
      },
    },
    {
      title: "Due date",
      dataIndex: "duedate",
      sorter: true,
      render: (i: string, record: ITableData, index: number) => {
        if (!!record.duedate) {
          return `${dayjs(record.duedate!).format("YYYY-MM-DD HH:mm")}`;
        } else {
          return `-`;
        }
      },
    },
    {
      title: "Task timer",
      dataIndex: "startTimes", // DJOLE
      sorter: (a: ITableData, b: ITableData) =>
        totalTimeFromTableData(a) - totalTimeFromTableData(b),
      render: (i: string, record: ITableData, index: number) => {
        const totalMs = totalTimeFromTableData(record);
        return dhm(totalMs);
      },
    },
    {
      title: "Staff",
      dataIndex: "name",
      sorter: true,
      render: (firstname: string, record, index) => (
        <div>
          <span className="smaller-text">
            {record.firstname} {record.lastName}
          </span>
          {!record.profile_image ? (
            <p>{record.name}</p>
          ) : (
            record.profile_image
              .split(",")
              .map((imgUrl, ind) => (
                <ImageWithHover
                  key={imgUrl + "_" + ind}
                  src={
                    "https://portal.mateam.net/uploads/staff_profile_images/" +
                    imgUrl
                  }
                  text={`${record.name} ${record.lastName}`}
                />
              ))
          )}
        </div>
      ),
      ...filterColumnProps(
        props.allStaff,
        (newVals: number[]) => {
          if (!newVals || newVals.length === 0) {
            performSearchByField("1", 1, "numberEq");
            return;
          }
          // performSearchByField("staff.staffId", newVals, "in");
          // performSearchByField("tskTimers.staff_id", newVals, "in");
          performSearchByField(
            `staff.staffId`,
            `staff.staffId IN (${newVals.join(
              ","
            )}) AND tskTimers.staff_id IN (${newVals.join(",")})`,
            "special"
          );
        },
        props.allStaff
      ),
    },
    {
      title: "Project",
      dataIndex: "projName",
      sorter: true,
      render: (name: string) => `${name}`,
      width: "20%",
      ...getColumnSearchProps("projName", (newVal: string) => {
        performSearchByField("proj.name", newVal, "like");
      }),
    },
    {
      title: "Client",
      dataIndex: "company",
      sorter: true,
      render: (name: string) => `${name}`,
      ...getColumnSearchProps("company", (newVal: string) => {
        performSearchByField("client.company", newVal, "like");
      }),
    },
  ];
  // const [getColumns, setColumns] = useState<ColumnsType<ITableData>>(columns);
  const performSearchByField = async (
    key: string,
    value: string | number | number[],
    compare: "eq" | "like" | "numberEq" | "in" | "special",
    altKeys?: string[]
  ) => {
    let str = `${key} LIKE '%${value}%'`;
    if (compare === "eq") {
      str = `${key} = '%${value}%'`;
    } else if (compare === "numberEq") {
      str = `${key} = ${value}`;
    } else if (compare === "in") {
      str = `${key} IN (${(value as number[]).join(",")})`;
    } else if (compare === "special") {
      str = value as string;
    }

    if (!!altKeys) {
      str = `(${str}`;
      altKeys.forEach((k) => {
        let str2 = `${k} LIKE '%${value}%'`;
        if (compare === "eq") {
          str2 = `${k} = '%${value}%'`;
        } else if (compare === "numberEq") {
          str2 = `${k} = ${value}`;
        }

        str = `${str} OR ${str2}`;
      });

      str = `${str})`;
    }

    // We have string, let's replace it with equivalent partner
    const queryWithSameFilter = componentState.current.searchFilter.find(
      (s) => s.indexOf(`${key}`) !== -1
    );
    let firstAvailableSpace = 0;
    if (queryWithSameFilter != null) {
      firstAvailableSpace =
        componentState.current.searchFilter.indexOf(queryWithSameFilter);
    } else {
      firstAvailableSpace = componentState.current.searchFilter.indexOf("1=1");
    }

    const newSearchFiler = [...componentState.current.searchFilter];
    newSearchFiler[firstAvailableSpace] = str;

    const newState = await getData(
      getState.pagination.current ?? componentState.current.pagination.current,
      getState.pagination.pageSize ??
        componentState.current.pagination.pageSize,
      componentState.current.range.from ?? range.from ?? null,
      componentState.current.range.to ?? range.to ?? null,
      componentState.current.groupBy,
      componentState.current.sorting,
      newSearchFiler,
      componentState.current,
      props.rangeFilter
    );
    setState(newState);
  };

  const rangeConfig: { from?: Date; to?: Date } = {};
  const componentState = useRef<cardFilter>({
    loading: false,
    data: [] as ITableData[],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      totalTime: 0,
    },
    groupBy: "Task",
    range: rangeConfig,
    sorting: { order: "", field: "" },
    searchFilter: columns.map((x) => "1=1"),
    hideInactive: true,
  });
  const [getState, setState] = useState({ ...componentState.current });

  const [shownColumns, setShownColumns] = useState(columns);
  const columnsConfiguration: ITableColumns = {
    allVisibleColumns: columns
      .filter((c) => c.title)
      .map((c) => c.title!.toString()),
    defaultVisibleColumns: shownColumns
      .filter((c) => c.title != null)
      .map((c) => c.title!.toString()),
    onVisibleColumnsUpdate: (visibleCols) => {
      setShownColumns(
        columns
          .filter((c) => c.title != null)
          .filter((c) =>
            visibleCols.find((visibleCol) => visibleCol === c.title!)
          )
      );
    },
  };

  const [range, setRange] = useState<IRangeData>({
    period: RangeDataOptions.AllTime,
  });
  const onPeriodUpdate = async (
    from: any | undefined,
    to: any | undefined,
    period: RangeDataOptions
  ) => {
    let startDate: Date | undefined;
    let endDate: Date | undefined;

    if (period === RangeDataOptions.ThisMonth) {
      startDate = dayjs().startOf("month").startOf("day").toDate();
      endDate = dayjs().endOf("month").endOf("day").toDate();
    } else if (period === RangeDataOptions.PrevMonth) {
      startDate = dayjs()
        .add(-1, "month")
        .startOf("month")
        .startOf("day")
        .toDate();
      endDate = dayjs().add(-1, "month").endOf("month").endOf("day").toDate();
    } else if (period === RangeDataOptions.AllTime) {
      startDate = undefined;
      endDate = undefined;
    } else if (period === RangeDataOptions.CustomPeriod) {
      startDate = from instanceof Date ? from : from?.toDate();
      endDate = to instanceof Date ? to : to?.toDate();
    } else if (period === RangeDataOptions.LastWeek) {
      startDate = dayjs()
        .add(-1, "week")
        .startOf("week")
        .startOf("day")
        .toDate();
      endDate = dayjs().add(-1, "week").endOf("week").endOf("day").toDate();
    } else if (period === RangeDataOptions.ThisWeek) {
      startDate = dayjs().startOf("week").startOf("day").toDate();
      endDate = dayjs().endOf("week").endOf("day").toDate();
    }

    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59);

    setRange({ from: startDate, to: endDate, period });
    const newState = await getData(
      componentState.current.pagination.current,
      componentState.current.pagination.pageSize,
      startDate ?? null,
      endDate ?? null,
      componentState.current.groupBy,
      componentState.current.sorting,
      componentState.current.searchFilter,
      componentState.current,
      props.rangeFilter
    );
    setState(newState);
  };

  const onGroupByChange = async (newGroupBy: string) => {
    const newState = await getData(
      1,
      componentState.current.pagination.pageSize,
      range.from ?? null,
      range.to ?? null,
      newGroupBy,
      componentState.current.sorting,
      componentState.current.searchFilter,
      componentState.current,
      props.rangeFilter
    );
    setState(newState);
  };

  const onHideInactiveChange = async (hideInactive: boolean) => {
    componentState.current.hideInactive = hideInactive;
    componentState.current.loading = true;

    const newState = await getData(
      1,
      componentState.current.pagination.pageSize,
      range.from ?? null,
      range.to ?? null,
      componentState.current.groupBy,
      componentState.current.sorting,
      componentState.current.searchFilter,
      componentState.current,
      props.rangeFilter
    );
    setState(newState);
  };

  const getLastSetting = () => {
    let prevFiltersString: string = localStorage.getItem(STORAGE_FILTER_KEY);
    const prevFilters: SettingsType[] =
      !prevFiltersString || prevFiltersString.length === 0
        ? []
        : JSON.parse(prevFiltersString);

    return prevFilters;
  };

  const onSaveFilter = (newName: string) => {
    const prevFilters: SettingsType[] = getLastSetting();

    const currFilter: SettingsType = {
      name: newName,
      range: componentState.current.range,
      searchFilter: componentState.current.searchFilter,
      sorting: componentState.current.sorting,
      groupBy: componentState.current.groupBy,
      shownColumnNames: shownColumns.map((x) => x.title.toString()),
    };

    prevFilters.push(currFilter);
    const stringifiedValues = JSON.stringify(prevFilters);
    localStorage.setItem(STORAGE_FILTER_KEY, stringifiedValues);
    setSavedFilters(prevFilters.map((x) => x.name));
  };

  const onSelectFilter = async (selectedName: string) => {
    if (!selectedName || selectedName.length === 0) {
      return;
    }

    const settings = getLastSetting();
    const activeSetting = settings.find((s) => s.name === selectedName);

    setRange(activeSetting.range);
    const newShownColumns = columns.filter(
      (c) =>
        activeSetting.shownColumnNames.indexOf(c.title.toLocaleString()) > -1
    );
    setShownColumns(newShownColumns);

    const newState = await getData(
      1,
      componentState.current.pagination.pageSize,
      dayjs(activeSetting.range.from).toDate(),
      dayjs(activeSetting.range.to).toDate(),
      activeSetting.groupBy,
      activeSetting.sorting,
      activeSetting.searchFilter,
      componentState.current,
      []
    );
    setState({ ...newState });
  };

  const onDeleteFilter = (v: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(`Do you wish to delete: ${v}?`)) {
      return;
    }

    const settings = getLastSetting().filter((s) => s.name !== v);
    const stringifiedValues = JSON.stringify(settings);
    localStorage.setItem(STORAGE_FILTER_KEY, stringifiedValues);
    setSavedFilters(settings.map((x) => x.name));
  };

  const handlePageChange = async (
    config: TablePaginationConfig,
    filterConfig: any,
    sorting: any
  ) => {
    if (sorting.order) {
      if (sorting.order === "descend") {
        sortingDirection = "DESC";
      } else {
        sortingDirection = "ASC";
      }
    } else {
      sortingKey = "";
      sortingDirection = "";
    }
    sortingKey = sorting.field;

    const newState = await getData(
      config.current || 1,
      config.pageSize ?? componentState.current.pagination.pageSize,
      range.from ?? null,
      range.to ?? null,
      componentState.current.groupBy,
      { field: sortingKey, order: sortingDirection },
      componentState.current.searchFilter,
      componentState.current,
      props.rangeFilter
    );
    setState(newState);
  };
  const [savedFilters, setSavedFilters] = useState(
    getLastSetting()?.map((x) => x.name) ?? []
  );

  useEffect(() => {
    getData(
      1,
      10,
      range.from ?? null,
      range.to ?? null,
      componentState.current.groupBy,
      {
        field: "",
        order: "",
      },
      componentState.current.searchFilter,
      componentState.current,
      props.rangeFilter
    ).then((r) => setState(r));
  }, [props.rangeFilter]);

  useEffect(() => {
    setShownColumns(columns);
  }, [props.allStaff]);

  return {
    handlePageChange,
    st: getState,
    columnsConfiguration,
    shownColumns,
    range,
    onPeriodUpdate,
    onGroupByChange,
    onSaveFilter,
    savedFilters,
    onSelectFilter,
    onDeleteFilter,
    onHideInactiveChange,
  };
};

const TableCard: React.FC<{
  rangeFilter?: string[];
  allStaff: any[];
  onHideInactiveChange: (val: boolean) => void;
}> = (props) => {
  console.warn("RERENDERING ");
  const {
    st,
    handlePageChange,
    columnsConfiguration,
    shownColumns,
    range,
    onPeriodUpdate,
    onGroupByChange,
    onSaveFilter,
    savedFilters,
    onSelectFilter,
    onDeleteFilter,
    onHideInactiveChange,
  } = UseTableCard(props);

  const hideInactiveWrapper = (val: boolean) => {
    onHideInactiveChange(val);
    props.onHideInactiveChange(val);
  };

  return (
    <div>
      <TableHeader
        columns={columnsConfiguration}
        rangeData={range}
        onPeriodUpdate={onPeriodUpdate}
        groupBy={st.groupBy}
        onGroupByChange={onGroupByChange}
        onSaveFilter={onSaveFilter}
        availableFilters={savedFilters}
        onSelectFilter={onSelectFilter}
        onDeleteFilter={onDeleteFilter}
        hideInactive={st.hideInactive}
        onHideInactiveChange={hideInactiveWrapper}
      />
      <Table
        columns={shownColumns}
        rowKey={(record) =>
          "" +
          record.taskId +
          record.id +
          record.staffId +
          record.startTimes +
          record.endTimes +
          record.clientId
        }
        dataSource={st.data}
        pagination={st.pagination}
        loading={st.loading}
        onChange={handlePageChange}
      />
      <div className="total-timer">
        Page time:{" "}
        {dhm(
          st.data.reduce((partialSum, d) => {
            const startTimes = d.startTimes.split(",");
            const endTimes = d.endTimes?.split(",") ?? "";

            let totalMs = 0;
            for (let i = 0; i < startTimes.length; i++) {
              const sTime = startTimes[i];
              const eTime = endTimes[i];

              const sDate = dayjs.unix(+sTime);
              const eDate = dayjs.unix(+eTime);

              totalMs += eDate.diff(sDate);
            }

            return partialSum + totalMs;
          }, 0),
          true
        )}
        <br />
        Total time: {secondsToHms(st.pagination.totalTime)}
        <br />
        Total items: {st.pagination.total}
      </div>
    </div>
  );
};

export default React.memo(TableCard);
