import { Anchor, Button, Input } from "antd";
import { useState } from "react";
import { useHistory } from "react-router";
import httpHelper from "../../services/http-helper";
const { Link } = Anchor;

const isValidEmail = (str: string): boolean => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
        return true;
    } else {
        return false;
    }
};

const isPasswordValid = (pw: string): boolean => {
    if (pw.length < 6) {
        return false;
    }
    if (pw.search(/[a-z]/i) < 0) {
        return false;
    }
    if (pw.search(/[0-9]/) < 0) {
        return false;
    }
    return true;
};

const useRegister = () => {
    const [email, setEmail] = useState<string>();
    const [pw, setPw] = useState<string>();
    const [confirmPw, setConfirmPw] = useState<string>();
    const history = useHistory();

    const registerFn = async () => {
        if (!isValidEmail(email ?? '')) {
            alert("Email is not valid");
        } else if (!isPasswordValid(pw ?? '')) {
            alert(
                "Password not valid. Must be 6 characters in length and must have at least 1 number and at least 1 letter"
            );
        } else if (pw !== confirmPw) {
            alert("Passwords are not the same.");
        } else {
            try {
                const res = await httpHelper.executeGetWithUsernamePass("authorization.php?register=true", email!, pw!);
                alert(res);
                history.push('/login');
            } catch (e: any) {
                alert(e?.response?.data ?? 'Cannot register, fatal error');
            }
        }
    }

    return {
        email,
        setEmail,
        pw,
        setPw,
        confirmPw,
        setConfirmPw,
        registerFn
    }
}

const Register = () => {
    const {
        email,
        setEmail,
        pw,
        setPw,
        confirmPw,
        setConfirmPw,
        registerFn
    } = useRegister();

    return (
        <div className="login-wrapper">
            <h2>Charts API</h2>
            <div className="login-dialog">
                <Input
                    placeholder="Email"
                    size="large"
                    value={email}
                    onChange={(ev) => {
                        setEmail(ev.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            registerFn();
                        }
                    }}
                />
                <br />
                <Input.Password
                    placeholder="Password"
                    size="large"
                    value={pw}
                    onChange={(ev) => {
                        setPw(ev.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            registerFn();
                        }
                    }}
                />
                <br />
                <Input.Password
                    placeholder="Confirm password"
                    size="large"
                    value={confirmPw}
                    onChange={(ev) => {
                        setConfirmPw(ev.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            registerFn();
                        }
                    }}
                />
                <br />
                <Button type="primary" onClick={registerFn}>
                    Register
                </Button>
                <br />
                <div className="other-options">
                    <Anchor>
                        <Link href="/login" title="Already have account? Login." />
                    </Anchor>
                </div>
            </div>
        </div>
    );
}

export default Register;