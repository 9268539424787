import { Anchor, Button, Input } from "antd";
import { useState } from "react";
import httpHelper from "../../services/http-helper";
const { Link } = Anchor;

const isValidEmail = (str: string): boolean => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
        return true;
    } else {
        return false;
    }
};

const UseForgot = () => {
    const [email, setEmail] = useState<string>();

    const resetFn = async () => {
        if (!isValidEmail(email!)) {
            alert('provided email is not valid!');
            return;
        }
        try {
            const res = await httpHelper.forgotPassword("authorization.php?forgot=true", email!);
            alert(res);
        } catch (e: any) {
            alert(e?.response?.data ?? 'Cannot reset password, fatal error');
        }
    };


    return {
        email,
        setEmail,
        resetFn
    }
}

const Forgot = () => {
    const { email, setEmail, resetFn } = UseForgot();

    return (
        <div className="login-wrapper">
            <div className="login-dialog">
                <Input
                    placeholder="Email"
                    size="large"
                    value={email}
                    onChange={(ev) => {
                        setEmail(ev.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            resetFn();
                        }
                    }}
                />
                <br />
                <Button type="primary" onClick={resetFn}>
                    Reset password
                </Button>
                <br />
                <div className="other-options">
                    <Anchor>
                        <Link href="/login" title="Back to login" />
                    </Anchor>
                </div>
            </div>
        </div>
    )
}

export default Forgot;