import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import TableCheckboxes from "../table-checkboxes/TableCheckboxes";

/**
 * Enum selection filter.
 * @param propertyName 
 * @param availableProps 
 * @param onApplied 
 * @returns 
 */
export const filterColumnProps = (
    availableProps: any[],
    onApplied: (values: number[]) => void,
    options: { label: string, value: number }[]) => {
    return ({
        filterDropdown: (data: { setSelectedKeys: (keys: string[]) => void, selectedKeys: string[], clearFilters: () => void }) => {
            return (
                <TableCheckboxes availableProps={availableProps} onApplied={onApplied} options={options} />
            )
        },
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: 'filtered' ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) => {
            onApplied(value);
            return true;
        },
    })
}

const getColumnSearchProps = (propertyName: string, onApplied: (value: string) => void) => ({
    filterDropdown: (data: { setSelectedKeys: (keys: string[]) => void, selectedKeys: string[], clearFilters: () => void }) => {
        setTimeout(() => {
            document.getElementById(`${propertyName}Id`)?.focus()
        }, 100)
        return (
            <div style={{ padding: 8 }}>
                <Input
                    id={`${propertyName}Id`}
                    placeholder={`Search ${propertyName}`}
                    value={data.selectedKeys[0]}
                    onChange={e => data.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(data.selectedKeys, onApplied)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(data.selectedKeys, onApplied)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(data.clearFilters, onApplied)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        )
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: 'filtered' ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
        record[propertyName]
            ? record[propertyName].toString().toLowerCase().includes(value.toLowerCase())
            : ''
});

const handleSearch = (selectedKeys: string[], onApplied: (value: string) => void) => {
    onApplied(selectedKeys[0]);
};

const handleReset = (clearFilters: () => void, callback: (newVal: string) => void) => {
    clearFilters();
    callback('');
};

export default getColumnSearchProps;