import "./TableHeader.css";
import { Select, DatePicker, Button, Checkbox } from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;

const groupByOptions = ["Task"];

// Todo, ova komponenta ce samo da cuva ID i odatle da se povlaci settings najbolje....
export interface ITableColumns {
  allVisibleColumns: string[];
  defaultVisibleColumns: string[];
  onVisibleColumnsUpdate: (cols: string[]) => void;
}

export enum RangeDataOptions {
  AllTime = "All Time",
  ThisMonth = "This Month",
  PrevMonth = "Last Month",
  CustomPeriod = "Custom Period",
  ThisWeek = "This Week",
  LastWeek = "Last Week",
}

export interface IRangeData {
  period: RangeDataOptions;
  from?: Date;
  to?: Date;
}

const VisibleColumns = (props: ITableColumns) => {
  return (
    <div className="visible-cols-wrapper">
      <span>Visible columns: </span>
      <Select
        className="table-header__visible-columns"
        mode="multiple"
        allowClear
        placeholder="Please select"
        defaultValue={props.defaultVisibleColumns}
        onChange={props.onVisibleColumnsUpdate}
      >
        {props.allVisibleColumns.map((col) => (
          <Option key={col} value={col}>
            {col}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const RangeConfiguration = (props: {
  rangeData: IRangeData;
  onPeriodUpdate: (
    from: Date | undefined,
    to: Date | undefined,
    period: RangeDataOptions
  ) => void;
}) => {
  const range: string[] = Object.values(RangeDataOptions);
  return (
    <div className="period-picker">
      <div>
        <span style={{ marginRight: "10px" }}>Period:</span>
        <Select
          style={{ width: 120 }}
          defaultValue={props.rangeData.period}
          onChange={(v: RangeDataOptions) => {
            props.onPeriodUpdate(props.rangeData.from, props.rangeData.to, v);
          }}
        >
          {range.map((k) => (
            <Option key={k} value={k}>
              {k}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <RangePicker
          disabled={
            props.rangeData.period == RangeDataOptions.CustomPeriod
              ? false
              : true
          }
          onChange={(dates: any, vals: any[]) => {
            const startDate = dates && dates.length > 0 ? dates[0] : undefined;
            const endDate = dates && dates.length > 0 ? dates[1] : undefined;
            props.onPeriodUpdate(startDate, endDate, props.rangeData.period);
          }}
        />
      </div>
    </div>
  );
};

const GroupByConfiguration = (props: {
  currValue: string;
  onValueChange: (val: string) => void;
}) => {
  return (
    <div className="header-row">
      <span>Group by:</span>
      <Select
        style={{ width: 120 }}
        defaultValue={props.currValue}
        onChange={(v: string) => {
          props.onValueChange(v);
        }}
      >
        <Option key="empty" value="">
          None
        </Option>
        {groupByOptions.map((k) => (
          <Option key={k} value={k}>
            {k}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const HideInactiveConfiguration = (props: {
  checked: boolean;
  onValueChange: (val: boolean) => void;
}) => {
  return (
    <div className="header-row">
      <Checkbox
        checked={props.checked}
        onChange={(ev) => props.onValueChange(ev.target.checked)}
      >
        Hide inactive users
      </Checkbox>
    </div>
  );
};

const SaveFilterOption = (props: {
  onSaveFilter: (name: string) => void;
  availableFilters: string[];
  onSelectFilter: (name: string) => void;
  onDeleteConf: (v: any) => void;
}) => {
  return (
    <div className="center-vertical push-right">
      <Select
        style={{ width: 120, margin: "0px 10px" }}
        allowClear
        onChange={(v) => props.onSelectFilter(v)}
        onDeselect={(v: any) => props.onDeleteConf(v)}
      >
        {props.availableFilters.map((x) => (
          <Option value={x}>{x}</Option>
        ))}
      </Select>

      <Button
        type="primary"
        onClick={() => {
          const newName = prompt("Enter name of filter");
          if (!!newName && newName.length > 0) {
            props.onSaveFilter(newName);
          }
        }}
      >
        Save current configuration
      </Button>
    </div>
  );
};

const TableHeader = (props: {
  columns: ITableColumns;
  rangeData: IRangeData;
  onPeriodUpdate: (
    from: Date | undefined,
    to: Date | undefined,
    period: RangeDataOptions
  ) => void;
  groupBy: string;
  onGroupByChange: (v: string) => void;
  onSaveFilter: (name: string) => void;
  availableFilters: string[];
  onSelectFilter: (name: string) => void;
  onDeleteFilter: (v: any) => void;
  hideInactive: boolean;
  onHideInactiveChange: (val: boolean) => void;
}) => {
  return (
    <div className="table-header">
      <VisibleColumns
        allVisibleColumns={props.columns.allVisibleColumns}
        defaultVisibleColumns={props.columns.defaultVisibleColumns}
        onVisibleColumnsUpdate={props.columns.onVisibleColumnsUpdate}
      />
      <div className="inline-div">
        <RangeConfiguration
          rangeData={props.rangeData}
          onPeriodUpdate={props.onPeriodUpdate}
        />
        <GroupByConfiguration
          currValue={props.groupBy}
          onValueChange={props.onGroupByChange}
        />
        <HideInactiveConfiguration
          checked={props.hideInactive}
          onValueChange={props.onHideInactiveChange}
        />
        <SaveFilterOption
          onSaveFilter={props.onSaveFilter}
          availableFilters={props.availableFilters}
          onSelectFilter={props.onSelectFilter}
          onDeleteConf={props.onDeleteFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
