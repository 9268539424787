import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/login/Login";
import { ILoginData, LoginProvider } from "./context/login/login-context";
import { useState, useEffect } from "react";
import httpHelper from "./services/http-helper";
import Home from "./pages/home/Home";
import Register from "./pages/register/Register";
import Forgot from "./pages/Forgot/Forgot";

const useApp = () => {
  const [loginData, setLoginData] = useState<ILoginData | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  const [range, setRange] = useState<string[]>([]);

  useEffect(() => {
    // Djole - due to time needed, bataljujem ovo za sad
    const loginDataStr = localStorage.getItem("loginData");
    const lData: ILoginData =
      loginDataStr == null ? null : JSON.parse(loginDataStr);

    setLoginData({ ...lData, range, setRange: (setRange as any) });
    setIsLoggedIn(
      window.location.pathname === "/login" || httpHelper.isLoggedIn()
    );
  }, []);

  // Logujemo range
  useEffect(() => {
    console.warn(range);
    setLoginData({ ...loginData as any, range, setRange });
  }, [range]);

  return {
    loginData,
    isLoggedIn
  };
};

const App = () => {
  const { loginData, isLoggedIn } = useApp();

  return (
    <LoginProvider value={loginData}>
      <Router>
        {!isLoggedIn ? (
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            
          </Switch>
        ) : (
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
        )}
      </Router>
    </LoginProvider>
  );
};

export default App;
