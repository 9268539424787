import React, { useEffect, useState } from "react";
import Card from "../../cards/card/Card";
import httpHelper from "../../services/http-helper";
import "./Home.css";
import { Modal } from "antd";
import TaskListDetails from "../../popup/task-list-details/TaskListDetails";
import HeaderDesktop from "../../common/header/Header";
import LoginContext from "../../context/login/login-context";
import { useHistory } from "react-router-dom";

const infoModal = (title: string, total: number, onOkFn: () => void) => {
  Modal.info({
    title,
    content: <TaskListDetails type={title} total={total}></TaskListDetails>,
    onOk() {
      onOkFn();
    },
    width: "60vw",
  });
};

const Home = () => {
  const [getCompletedTasks, setCompletedTasks] = useState<number>(0);
  const [getIncompleteTasks, setIncompleteTasks] = useState<number>(0);
  const [getOverdueTasks, setOverdueTasks] = useState<number>(0);
  const [getTotalTasks, setTotalTasks] = useState<number>(0);
  const [getOpenedTasks, setOpenedTasks] = useState<number>(0);

  const [getTskByCntOptions, setTskByCntOptions] =
    useState<Highcharts.Options>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const globalContext = React.useContext(LoginContext);

  const [myRange, setMyRange] = useState<string[] | undefined>([]);
  const [tasksByUser, setTasksByUser] = useState<
    {
      cnt: number;
      firstname: string;
      lastname: string;
      profile_image: string;
    }[]
  >([]);
  const [allStaff, setAllStaff] = useState<{ label: string; value: number }[]>(
    []
  );

  const history = useHistory();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const openModal = (taskList: string, total: number) => {
    // setIsModalVisible(true);
    infoModal(taskList, total, () => {});
  };

  // API calls
  const getTasksByType = async (
    type: string,
    callback: (res: number) => void
  ) => {
    httpHelper
      .post<unknown, { cnt: number }[]>("chart-helper.php", {
        method: "getTasksCountByStatus",
        status: type,
        range: globalContext?.range,
      })
      .then(
        (res) => {
          callback(res[0].cnt);
        },
        (err) => {
          if (err?.response?.status === 401) {
            history.push("/login");
          }
        }
      );
  };

  const getTasksByUser = async () => {
    httpHelper
      .post<
        unknown,
        {
          cnt: number;
          firstname: string;
          lastname: string;
          profile_image: string;
        }[]
      >("chart-helper.php", {
        method: "getTasksCntByUser",
        range: globalContext?.range,
      })
      .then((res) => {
        const categories = res.map((r) => `${r.firstname} ${r.lastname}`);
        const datas = res.map((r) => +r.cnt);
        setTasksByUser(res);

        const tasksByUserOptions: Highcharts.Options = {
          title: {
            text: "Tasks by user",
          },
          xAxis: {
            categories,
            labels: {
              useHTML: true,
              formatter: (ctx) => {
                const d = res[ctx.pos];
                return `<img src="https://portal.mateam.net/uploads/staff_profile_images/${d.profile_image}" width="25px" height="25px" style="border-radius: 50%; object-fit: contain" />`;
              },
            },
          },
          yAxis: {
            min: 0,
            max: Math.max(...res.map((r) => r.cnt)) + 1,
            title: {
              text: "Task count",
            },
          },
          series: [
            {
              type: "column",
              name: "Tasks",
              data: datas,
            },
          ],
        };

        setTskByCntOptions(tasksByUserOptions);
      });
  };

  const getAllStaff = async (onlyVisible: boolean) => {
    const res = await httpHelper.post<
      unknown,
      { nameSurname: string; staffId: number }[]
    >("chart-helper.php", { method: "getAllStaff", onlyVisible });
    const newStaff: { label: string; value: number }[] = [];
    res.forEach((r) => {
      newStaff.push({
        label: r.nameSurname,
        value: r.staffId,
      });
    });
    setAllStaff(newStaff.sort((a, b) => (a.label > b.label ? 1 : -1)));
  };

  const collectAllData = () => {
    getTasksByType("completed", (r) => setCompletedTasks(r));
    getTasksByType("incomplete", (r) => setIncompleteTasks(r));
    getTasksByType("all", (r) => setTotalTasks(r));
    getTasksByType("overdue", (r) => setOverdueTasks(r));
    getTasksByType("inProgress", (r) => setOpenedTasks(r));
    getTasksByUser();
    getAllStaff(true);
  };

  // API calls end

  // On range change, we redo everything?
  useEffect(() => {
    if (myRange !== globalContext?.range) {
      setMyRange(globalContext?.range);
      collectAllData();
    }
  });

  /* djolee
  Opened tasks - Svi taskovi sa trenutnim ukljucenim tajmerom
  Overdue tasks - Svi taskovi kojima je probijen rok due date.
  */
  return (
    <div className="whole-page">
      <HeaderDesktop />
      <div className="home">
        <div className="home-row">
          <Card
            cardType="shortInfo"
            title="Total tasks"
            data={getTotalTasks}
            onDetailsClick={() => openModal("all", getTotalTasks)}
          />
          <Card
            cardType="shortInfo"
            title="Completed tasks"
            data={getCompletedTasks}
            onDetailsClick={() => openModal("completed", getCompletedTasks)}
          />
          <Card
            cardType="shortInfo"
            title="Incomplete tasks"
            data={getIncompleteTasks}
            onDetailsClick={() => openModal("incomplete", getIncompleteTasks)}
          />
          <Card
            cardType="shortInfo"
            title="Opened tasks"
            data={getOpenedTasks}
            onDetailsClick={() => openModal("inProgress", getOpenedTasks)}
          />
          <Card
            cardType="shortInfo"
            title="Overdue tasks"
            data={getOverdueTasks}
            onDetailsClick={() => openModal("overdue", getOverdueTasks)}
          />
        </div>

        <div className="home-row">
          <Card
            cardType="active-tasks"
            title="Active tasks"
            range={globalContext?.range}
          />
          <Card
            cardType="chart"
            chartType="column"
            title="Tasks by users"
            chartDataOptions={getTskByCntOptions}
            tasksByUser={tasksByUser}
          />
        </div>

        {allStaff.length === 0 ? (
          <div></div>
        ) : (
          <Card
            cardType="table"
            range={globalContext?.range}
            allStaff={allStaff}
            onHideInactiveChange={(v) => getAllStaff(v)}
          />
        )}

        <Card cardType="due-tasks" allStaff={allStaff}/>

        <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
      </div>
    </div>
  );
};

export default Home;
