import ActiveTasksTableCard from "../active-tasks-table-card/ActiveTasksTableCard";
import Chart from "../chart/Chart";
import ShortInfoCard from "../short-info-card/ShortInfoCard";
import TableCard from "../table-card/TableCard";
import TableTasksCount from "../table-task-count/TableTasksCount";
import TasksDueComponent from "../tasks-due/TasksDue";
import "./Card.css";

const Card = (props: {
  cardType: "chart" | "table" | "shortInfo" | "active-tasks" | "due-tasks" | "";
  title?: string;
  data?: string | number;
  chartType?: string;
  chartDataOptions?: Highcharts.Options;
  onDetailsClick?: () => void;
  range?: string[];
  tasksByUser?: {
    cnt: number;
    firstname: string;
    lastname: string;
    profile_image: string;
  }[];
  allStaff?: any[];
  onHideInactiveChange?: (val: boolean) => void;
}) => {
  if (props.cardType === "table") {
    return (
      <div className="card-wrapper card-wrapper--full">
        <TableCard
          rangeFilter={props.range}
          allStaff={props.allStaff ?? []}
          onHideInactiveChange={(v) => props.onHideInactiveChange(v)}
        />
      </div>
    );
  } else if (props.cardType === "shortInfo") {
    return (
      <div className="card-wrapper card-wrapper--mini">
        <ShortInfoCard
          title={props.title!}
          data={props.data!}
          onDetailsClick={props.onDetailsClick}
        />
      </div>
    );
  } else if (props.cardType === "active-tasks") {
    return (
      <div className="card-wrapper card-wrapper--half card-800h">
        <h1 className="card-title">{props.title}</h1>
        <ActiveTasksTableCard rangeFilter={props.range} />
      </div>
    );
  } else if (props.cardType === "due-tasks") {
    return (
      <div className="card-wrapper card-wrapper--full card-700h">
        <TasksDueComponent allStaff={props.allStaff}></TasksDueComponent>
      </div>
    );
  }

  return (
    <div className="card-wrapper card-wrapper--half card-800h">
      <Chart type={props.chartType} chartDataOptions={props.chartDataOptions} />
      <TableTasksCount tasksByUser={props.tasksByUser ?? []} />
    </div>
  );
};

export default Card;
