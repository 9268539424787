import { useEffect, useState } from 'react';
import httpHelper from '../../services/http-helper';
import { ColumnsType } from "antd/lib/table";
import ImageWithHover from '../../common/image-with-hover/ImageWithHover';
import { Table } from "antd";
import './ActiveTasksTableCard.css';
import React from 'react';
import dayjs from 'dayjs';
import { dhm } from '../table-card/TableCard';

interface IActiveUserTableData {
    taskId: string;
    taskName: string;
    activeStaffId: string;
    activeStaffName: string;
    activeStaffImage: string;
    activationStart: string; // unix

    currentTime: number; // Kolko ovaj taskTimer traje
    totalByStaff: number; // Kolko je ovaj covek ukupno radio
    totalTime: number // Kolko su svi radili ukupno

    otherUsers: {
        staffName: string;
        staffImage: string;
    }[]
}

interface IActiveField {
    taskId: string;
    taskName: string;
    activeStaffId: string;
    staffName: string;
    staffImage: string;
    currentTimeStart: string;
}

interface IOthersField {
    taskId: string;
    staffName: string;
    staffImage: string;
    startTime: string; //UNIX
    endTime: string;
}

const columns: ColumnsType<IActiveUserTableData> = [
    {
        title: "Active user",
        dataIndex: "activeProfileName",
        width: 70,
        render: (i: string, record: IActiveUserTableData, index: number) => {
            return <ImageWithHover text={`${record.activeStaffName}`} src={'https://portal.mateam.net/uploads/staff_profile_images/' + record.activeStaffImage} />;
        }
    },
    {
        title: "Users",
        dataIndex: "staffMembers",
        render: (i: string, record: IActiveUserTableData, index: number) => {
            return (
                <div className="image-arr">{
                    record.otherUsers.map(otherUser => {
                        return <ImageWithHover text={otherUser.staffName} src={'https://portal.mateam.net/uploads/staff_profile_images/' + otherUser.staffImage} />;
                    })}</div>
            );
        }
    },
    {
        title: "Task",
        dataIndex: "taskName",
        render: (name: string, record: IActiveUserTableData) => {
            return (<a className="href-fill" href={"https://portal.mateam.net/admin/tasks/view/" + record.taskId} target="_blank" rel="noreferrer">{name}</a>);
        }
    },
    {
        title: "Current time",
        dataIndex: "currentTimeStart",
        render: (currentTimeStart: string, record: IActiveUserTableData) => {
            return dhm((record.currentTime) * 1000)
        }
    },
    {
        title: "Total by stuff",
        dataIndex: "totalTimeByStaff",
        render: (totalTimeByStaff: string, record: IActiveUserTableData) => {
            return dhm((record.totalByStaff) * 1000)
        }
    },
    {
        title: "Total time",
        dataIndex: "totalTime",
        render: (totalTime: string, record: IActiveUserTableData) => {
            return dhm((record.totalTime) * 1000)
        }
    }
];

const UseActiveTasks = (props: { rangeFilter?: string[] }) => {
    const [data, setData] = useState<IActiveUserTableData[]>();
    useEffect(() => {
        const getActiveTasks = async () => {
            httpHelper
                .post<unknown, { active: IActiveField[], others: IOthersField[] }>("chart-helper.php", {
                    method: "getActiveTasks",
                    range: props?.rangeFilter
                })
                .then((res) => {
                    const newTableData: IActiveUserTableData[] = [];
                    res.active.forEach(active => {
                        const currTimestamp = dayjs().unix();
                        const currentActiveTimerRunning = currTimestamp - +active.currentTimeStart;

                        const othersForActive = res.others.filter(o => o.taskId === active.taskId);
                        const activePreviousTimers = othersForActive.filter(o => o.staffImage === active.staffImage);

                        let myTotalTime = currentActiveTimerRunning;
                        activePreviousTimers.forEach(p => {
                            myTotalTime += (+p.endTime - +p.startTime);
                        });

                        let totalTime = currentActiveTimerRunning;
                        othersForActive.forEach(o => {
                            totalTime += (+o.endTime - +o.startTime);
                        });

                        let otherUsersDistinct = othersForActive.map(o => ({
                            staffImage: o.staffImage,
                            staffName: o.staffName
                        }));
                        otherUsersDistinct = otherUsersDistinct.filter((val, i, s) => s.findIndex(x => x.staffName === val.staffName) === i);


                        newTableData.push({
                            activeStaffId: active.activeStaffId,
                            activeStaffImage: active.staffImage,
                            activeStaffName: active.staffName,
                            taskId: active.taskId,
                            taskName: active.taskName,
                            activationStart: active.currentTimeStart,
                            currentTime: currentActiveTimerRunning,
                            totalByStaff: myTotalTime,
                            totalTime: totalTime,

                            otherUsers: otherUsersDistinct
                        })
                    });

                    setData(newTableData);
                });
        }

        getActiveTasks();
    }, [props.rangeFilter]);

    const openWindow = (id: string) => {
        window.open(`https://portal.mateam.net/admin/tasks/view/${id}`, '_blank')?.focus();
    }

    return {
        data,
        openWindow
    }
}

const ActiveTasksTableCard: React.FC<{ rangeFilter?: string[] }> = (props) => {
    const { data, openWindow } = UseActiveTasks(props);

    return (
        <Table
            columns={columns}
            rowKey={(record) =>
                record.taskId + record.activeStaffImage + record.activationStart + record.totalTime
            }
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        openWindow((data as IActiveUserTableData[])[rowIndex!].taskId)
                    }, // click row
                };
            }}
            dataSource={data}
        />
    )
}

export default ActiveTasksTableCard;