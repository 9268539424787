import './ImageWithHover.css';

const ImageWithHover = (props: { src: string, text: string, size?: number, rounded?: boolean }) => {
    const size = props.size ?? 50;
    const borderRadius = props.rounded == null || !!props.rounded ? '50%' : '0px';
    const src = !props.src || props.src.endsWith('/null') ? 'https://portal.mateam.net/assets/images/user-placeholder.jpg' : props.src;
    return (
        <div className="click-to-top">
            <img src={src} alt={props.text} width={size} height={size} style={{ borderRadius: borderRadius }} />
            <span>{props.text}</span>
        </div>
    )
}

export default ImageWithHover;