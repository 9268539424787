import './ShortInfoCard.css';

const ShortInfoCard: React.FC<{ title: string, data: string | number, onDetailsClick?: () => void }> = (props) => {
    return (
        <div className="info-card" onClick={() => {
            if (!!props.onDetailsClick) {
                props?.onDetailsClick();
            }
        }}>
            <h3 className="info-title">{props.title}</h3>
            <p>{props.data}</p>
            <p className="info-label">Task count</p>
        </div>
    );
}

export default ShortInfoCard;