import axios from "axios";

class HttpHelper {
  private _baseUrl = `https://charts.mateam.net/php`;
  private pToken: string | null;

  constructor() {
    this.pToken = localStorage.getItem("pToken");
  }

  async executeGetWithUsernamePass<T>(
    relativePath: string,
    username: string,
    password: string
  ): Promise<{ data: T; header: unknown }> {
    const resp = await axios.post<T>(
      `${this._baseUrl}/${relativePath}`,
      {
        email: username,
        password: password,
      },
      {
        auth: {
          username,
          password,
        },
        data: {
          email: username,
          password,
        }
      }
    );

    if (resp.headers["pToken"]) {
      this.pToken = resp.headers["pToken"];
      localStorage.setItem("pToken", this.pToken || "");
    } else {
      this.pToken = (resp.data as any).toString();
      localStorage.setItem("pToken", this.pToken || "");
    }

    return {
      data: resp.data,
      header: resp.headers,
    };
  }

  async forgotPassword(relativePath: string, email: string) {
    const res = await axios.post<{ data: unknown }>(`${this._baseUrl}/${relativePath}`, {
      email
    }, {
    });

    return res.data;
  }

  async post<Tinput, Toutput>(relativePath: string, body?: Tinput): Promise<Toutput> {
    const res = await axios.post<Toutput>(`${this._baseUrl}/${relativePath}`, body, {
      headers: {
        'pToken': this.pToken,
      },
    });

    return res.data as any;
  }

  isLoggedIn(): boolean {
    return this.pToken != null && this.pToken.length > 0;
  }
}

export default new HttpHelper();
