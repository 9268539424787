import "./TaskListDetails.css";
import httpHelper from "../../services/http-helper";
import { useEffect, useState } from "react";
import { Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import ImageWithHover from "../../common/image-with-hover/ImageWithHover";

interface ITableData {
    id: string,
    taskname: string,
    duedate: Date,
    staffMembers: string // djole$$$putanja.png,gaga$$$putanja.png,todi$$$putanja.png
}

const columns: ColumnsType<ITableData> = [
    {
        title: "Task name",
        dataIndex: "taskname",
        sorter: true,
        render: (taskname: string, record: ITableData) => {
            return (<a className="href-fill" href={"https://portal.mateam.net/admin/tasks/view/" + record.id} target="_blank" rel="noreferrer">{taskname}</a>);
        }
    },
    {
        title: "Staff",
        dataIndex: "staffMembers",
        render: (i: string, record: ITableData, index: number) => {
            return (
                <div className="image-arr">
                {
                    record.staffMembers.split(',').map(nameByUrl => {
                        const namePathArr = nameByUrl.split('$$$');
                        return <ImageWithHover text={`${namePathArr[0]}`} src={'https://portal.mateam.net/uploads/staff_profile_images/' + namePathArr[1]} />;
                    })
                }
                </div>
            );
        }
    },
    {
        title: "Due date",
        dataIndex: "duedate",
        render: (duedate: Date) => `${duedate ? dayjs(duedate).format('YYYY-MM-DD') : '-'}`,
    }
]

const UseTaskListDetails = (tasksType: string, totalItems: number) => {
    const [page, setPage] = useState<number>(0);
    const [data, setData] = useState<ITableData[]>([]);
    const [getState, setState] = useState({
        loading: false,
        pagination: {
            current: 1,
            pageSize: 10,
            total: totalItems,
        }
    });

    const getTasksOfType = async (limit: number, newPage: number) => {
        httpHelper
            .post<unknown, ITableData[]>("chart-helper.php", {
                method: "getTasksByStatus",
                status: tasksType,
                page: newPage,
                limit
            })
            .then((res) => {
                setPage(newPage);
                setState({
                    ...getState,
                    pagination: {
                        ...getState.pagination,
                        current: newPage === 0 ? 1 : newPage,
                        pageSize: limit
                    }
                });
                setData([...res]);
            });
    }

    const handlePageChange = (
        config: TablePaginationConfig,
        filterConfig: any,
        sorting: any
    ) => {
        getTasksOfType(getState.pagination.pageSize, config.current || 1);
    };

    useEffect(() => {
        getTasksOfType(10, 0);
    }, []);

    return {
        getState,
        handlePageChange,
        data
    }
}

const TaskListDetails: React.FC<{ type: string, total: number }> = (props) => {
    const { getState, data, handlePageChange } = UseTaskListDetails(props.type, props.total);

    return (
        <div className="task-details-wrapper">
            <Table
                columns={columns}
                rowKey={(record) =>record.taskname}
                dataSource={data}
                pagination={getState.pagination}
                loading={getState.loading}
                onChange={handlePageChange}
            />
        </div>
    )
}

export default TaskListDetails;