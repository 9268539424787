import { Checkbox } from 'antd';
import React from 'react';

const TableCheckboxes: React.FC<{
    availableProps: any[],
    onApplied: (values: number[]) => void,
    options: { label: string, value: number }[]
}> = (props) => {
    const allOptions = props.options.map(o => o.label);
    const [checkedList, setCheckedList] = React.useState(allOptions);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(true);

    const onChange = (list: any) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < allOptions.length);
        setCheckAll(list.length === allOptions.length);

        // Now translate this back to parent...
        const translatedOptions: number[] = props.options
            .filter(f => !!f)
            .map((prop, pos) => !list.find((label: string) => label === prop.label) ? -1 : prop.value)
            .filter(x => x !== -1);

        props.onApplied(list.length === 0 ? [-999] : translatedOptions)
    };

    const onCheckAllChange = (e: any) => {
        const newCheckedList = e.target.checked ? allOptions : [];
        setIndeterminate(false);
        setCheckAll(e.target.checked);

        // Call
        onChange(newCheckedList);
    };

    return (
        <>
            <div style={{ padding: '5px' }}>
                <Checkbox style={{ marginBottom: '10px' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    Check all
                </Checkbox>
                <Checkbox.Group style={{ display: 'flex', flexFlow: 'column' }} options={allOptions} value={checkedList} onChange={onChange} />
            </div>
        </>
    );
}

export default TableCheckboxes;