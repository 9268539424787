import { Select } from "antd";
import { Table, TablePaginationConfig, DatePicker } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ImageWithHover from "../../common/image-with-hover/ImageWithHover";
import { filterColumnProps } from "../../common/table-search/table-search";
import httpHelper from "../../services/http-helper";
const { Option } = Select;

const { RangePicker } = DatePicker;

interface ITableData {
  // firstname: string;
  // lastName: string;
  // profile_image: string;
  // staffId: number;
  staffIds: string;
  staffMembers: string;

  customerName: string;

  projectName: string;

  taskId: number;
  taskname: string;

  duedate: Date;
  // count?
}

const getColumns = (
  allStaff: any[],
  searchCallback: (usrs: string[]) => void
) => {
  const columns: ColumnsType<ITableData> = [
    {
      title: "Staff",
      dataIndex: "staffMembers",
      render: (i: string, record: ITableData, index: number) => {
        return (
          <div className="image-arr">
            {!record.staffMembers
              ? ""
              : record.staffMembers.split(",").map((nameByUrl, ind) => {
                  const namePathArr = nameByUrl.split("$$$");
                  return (
                    <ImageWithHover
                      key={nameByUrl + "_" + ind}
                      text={`${namePathArr[0]}`}
                      src={
                        "https://portal.mateam.net/uploads/staff_profile_images/" +
                        namePathArr[1]
                      }
                    />
                  );
                })}
          </div>
        );
      },
      ...filterColumnProps(
        allStaff,
        (newVals: number[]) => {
          if (!newVals || newVals.length === 0) {
            searchCallback([]);
            return;
          }
          const extractedStaff = allStaff.filter(
            (val) => newVals.findIndex((x) => x === val.value) > -1
          );
          searchCallback(extractedStaff.map((val) => val.value));
        },
        allStaff
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (name: string) => `${name}`,
    },
    {
      title: "Project name",
      dataIndex: "projectName",
      render: (name: string) => `${name}`,
    },
    {
      title: "Task name",
      dataIndex: "taskname",
      sorter: true,
      render: (name: string, record) => {
        return (
          <a
            className="href-fill"
            href={"https://portal.mateam.net/admin/tasks/view/" + record.taskId}
            target="_blank"
            rel="noreferrer"
          >
            {name}
          </a>
        );
      },
    },
    {
      title: "Due date",
      dataIndex: "duedate",
      render: (i: string, record: ITableData, index: number) => {
        return `${dayjs(record.duedate).format("YYYY-MM-DD HH:mm")}`;
      },
    },
  ];

  return columns;
};

const SelectComponent: React.FC<{
  handleChange: (value: string, option: any) => void;
}> = (props) => {
  return (
    <Select
      defaultValue="due_today"
      style={{ width: 120 }}
      onChange={props.handleChange}
    >
      <Option value="due_today">Due today</Option>
      <Option value="due_tomorrow">Due tommorow</Option>
      <Option value="due_this_week">Due this week</Option>
      <Option value="due_next_week">Due next week</Option>
      <Option value="due_this_month">Due this month</Option>
      <Option value="due_next_month">Due next month</Option>
      <Option value="period">Period</Option>
    </Select>
  );
};

const UseTasksDueComponent = () => {
  const [range, setRange] = useState("");
  const onRangeTypeChange: (value: string, option: any) => void = (
    val,
    option
  ) => {
    let date = dayjs().startOf("day").toDate();
    setRange(val);
    switch (val) {
      case "due_today":
        date = dayjs().startOf("day").toDate();
        break;
      case "due_tomorrow":
        date = dayjs().add(1, "day").startOf("day").toDate();
        break;
      case "due_this_week":
        date = dayjs().endOf("week").toDate();
        break;
      case "due_next_week":
        date = dayjs().add(7, "day").endOf("week").toDate();
        break;
      case "due_this_month":
        date = dayjs().endOf("month").toDate();
        break;
      case "due_next_month":
        date = dayjs().add(1, "month").endOf("month").toDate();
        break;
      case "period":
        // newVals.push(dayjs().add(-7, 'day').startOf('day'));
        // newVals.push(dayjs().endOf('day'));
        break;
    }

    //dateChange pozovi
    getData(1, getState.pagination.pageSize, getState.searchFilter, date);
  };
  const [getState, setState] = useState({
    loading: false,
    data: [] as ITableData[],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    dueDate: dayjs().startOf("day").toDate(),
    searchFilter: "",
  });
  const [staffFilter, setStaffFilter] = useState([]);

  const getData = async (
    current = 1,
    pageSize = 10,
    searchFilter: string,
    dueDate: Date,
    startDueDate?: Date
  ) => {
    setState({
      ...getState,
      loading: true,
    });

    const startDue = !startDueDate
      ? dayjs(dueDate).add(-1, "day").format("YYYY-MM-DD")
      : dayjs(startDueDate).add(-1, "day").format("YYYY-MM-DD");

    const endDue = dayjs(dueDate).add(1, "day").format("YYYY-MM-DD");
    const res = await httpHelper.post<
      unknown,
      { data: ITableData[]; total: number }
    >("chart-helper.php", {
      method: "getTasksDue",
      page: current - 1,
      limit: pageSize,
      startDue,
      endDue,
      staffs: staffFilter.join(","),
    });

    setState({
      ...getState,
      loading: false,
      data: res.data,
      pagination: {
        current: current,
        pageSize: pageSize,
        total: res.total,
      },
      dueDate,
      searchFilter: searchFilter,
    });
  };

  const handlePageChange = (
    config: TablePaginationConfig,
    filterConfig: any,
    sorting: any
  ) => {
    getData(
      config.current || 1,
      getState.pagination.pageSize,
      getState.searchFilter,
      getState.dueDate
    );
  };

  useEffect(() => {
    getData(
      1,
      getState.pagination.pageSize,
      getState.searchFilter,
      getState.dueDate
    );
  }, [staffFilter]);

  const onRangeSelected = (range: string[]) => {
    if (!range || !range[0] || !range[1]) return;
    getData(
      1,
      getState.pagination.pageSize,
      getState.searchFilter,
      dayjs(range[1]).toDate(),
      dayjs(range[0]).toDate()
    );
  };

  return {
    onRangeTypeChange,
    getState,
    handlePageChange,
    onRangeSelected,
    range,
    onStaffSelected: (staff: string[]) => setStaffFilter(staff),
  };
};

const dateChange = (
  values: any[],
  formatString: string[],
  changeCallback: (r: string[]) => void
) => {
  console.log("START DATE CHANGE");
  console.log(values);
  if (!!values && values.length > 0) {
    console.log(values[0].toDate());
    changeCallback(values.map((v) => v.format("YYYY-MM-DD")));
  } else {
    changeCallback([]);
  }
};

const TasksDueComponent: React.FC<{ allStaff: any[] }> = (props) => {
  const {
    getState,
    handlePageChange,
    onRangeTypeChange,
    onRangeSelected,
    range,
    onStaffSelected,
  } = UseTasksDueComponent();

  const columns = getColumns(props.allStaff, onStaffSelected);

  return (
    <div className="due-wrapper">
      <SelectComponent handleChange={onRangeTypeChange}></SelectComponent>
      <div>
        <RangePicker
          onChange={(values, formatString) =>
            dateChange(values as any[], formatString, onRangeSelected)
          }
          disabled={range !== "period"}
        />
        <Table
          columns={columns}
          rowKey={(record) =>
            record.taskId + record.projectName + record.customerName
          }
          dataSource={getState.data}
          pagination={getState.pagination}
          loading={getState.loading}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TasksDueComponent;
