import './TableTasksCount.css';
import { Table, TablePaginationConfig } from "antd";
import ImageWithHover from "../../common/image-with-hover/ImageWithHover";
import { ColumnsType } from "antd/lib/table";

interface ITableData {
    cnt: number,
    firstname: string,
    lastname: string,
    profile_image: string
}

const columns: ColumnsType<ITableData> = [
    {
        title: "Staff",
        dataIndex: "firstname",
        sorter: true,
        render: (staff: string, record) => {
            return (
                <ImageWithHover src={"https://portal.mateam.net/uploads/staff_profile_images/" + record.profile_image} text={`${record.firstname} ${record.lastname}`} />
            );
        },
        width: 100
    },
    {
        title: "Tasks",
        dataIndex: "cnt",
        sorter: true,
        render: (cnt: number) => `${cnt}`,
    },
]

const TableTasksCount: React.FC<{
    tasksByUser: ITableData[]
}> = (props) => {
    return (<div>
        <Table
            columns={columns}
            rowKey={(record) =>
                "" +
                record.firstname +
                record.lastname
            }
            dataSource={props.tasksByUser}
            pagination={{ pageSize: 5 }}
            scroll={{ y: 240 }}
        />
    </div>);
}

export default TableTasksCount;