import React from "react";

const LoginContext = React.createContext<ILoginData | undefined>(undefined);

export interface ILoginData {
  name: string;
  pToken: string;
  range: string[];
  setRange: (r: string[]) => { }
  // Ovde ce da ide settings
}

export const LoginProvider = LoginContext.Provider;
export default LoginContext;
