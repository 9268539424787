import "./Chart.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Chart = (props: { type?: string, chartDataOptions?: Highcharts.Options }) => {
  //UseChart(props);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={props.chartDataOptions} />
    </div>
  );
};

export default Chart;
